import "./Academics.css";
import { CellMeasurer, Hero, PageCaption } from "../../components";
import backgroundImage from "../../images/academics-hero.jpg";
import Nav from "../../layout/Nav";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { isMobile } from "react-device-detect";
import { useQuery } from "@apollo/client";
import { POLICIES } from "../../api";
import { usePageTracking } from "../../utils/usePageTracking";

function DualLanguageSection() {
  const ref = useRef(null);

  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div className="Academics__section app-grid" ref={ref}>
      <div className="Academics__section__content ">
        <div
          className="section-title"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa’s Dual Language Program
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          Designed to surpass state curriculum standards while providing the
          opportunity for students to become proficient in two languages, HoLa
          offers a traditional core curriculum through instruction in both
          Spanish and English to a diverse student population. HoLa’s student
          body is culturally, ethnically, linguistically, and socioeconomically
          diverse. HoLa is designated a Model Program by the NJDOE and serves as
          a resource to other programs of its kind from across the state and
          beyond.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          Research consistently demonstrates that students in dual language
          classrooms thrive academically, outperform their monolingual peers on
          standardized achievement tests, and excel in measures of cognitive
          development, flexible thinking and problem solving. HoLa utilizes a
          well-established immersion approach to dual language education and
          incorporates a range of opportunities for creative expression and
          experiential learning.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          Instruction is differentiated across content areas to ensure that all
          students thrive and are challenged appropriately.
        </div>

        <div
          className="Academics__section__content__image"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          <img src={"academics-1.png"} alt="" />
        </div>
      </div>
    </div>
  );
}

function ElementarySection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div className="Academics__section app-grid" ref={ref}>
      <div className="Academics__section__content ">
        <div
          className="section-title"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          Elementary School Curriculum
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          HoLa’s elementary curriculum provides a rigorous academic program in a
          dual language immersion environment. The curriculum is
          interdisciplinary, challenging, and aligned with the New Jersey
          Student Learning Standards. Our immersion approach is interactive and
          experiential, with a wealth of opportunities to learn by doing and to
          use language in meaningful ways (listening, reading, writing, and
          speaking) in every content area.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.25s",
          }}
        >
          Immersion is the most effective form of dual language instruction and
          provides the strongest outcomes in both language proficiency and
          academic performance. Early extended immersion programs have a
          positive cognitive impact on the developing brain and increase problem
          solving and creative thinking skills.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
          }}
        >
          Teachers implement a range of immersion strategies and techniques to
          support students’ content comprehension, regardless of language
          background, and the curriculum is extended and reinforced through
          projects, presentations, and field trips.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.35s",
          }}
        >
          In early Elementary (K-2nd grades), HoLa classrooms are
          self-contained, with a dedicated lead teacher and apprentice teacher
          team. Students learn in Spanish for most of the day with just 1-2
          periods of instruction in English (including English Language Arts and
          Math).
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.45s",
          }}
        >
          In upper Elementary (3rd-4th grades), classrooms shift to a
          side-by-side model, with two lead teachers providing instruction in
          designated content areas. The language distribution becomes more
          balanced, with increased English during academic periods (50/50 by 4th
          grade).
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.45s",
          }}
        >
          Specials classes and Morning Meeting are in Spanish at every grade
          level.
        </div>
      </div>
    </div>
  );
}

function MiddleSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <CellMeasurer>
      {({ squareRef, dimensions }) => (
        <div className="Academics__section app-grid" ref={ref}>
          <div className="Academics__section__content ">
            <div
              className="Academics__section__content__row "
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(12, minmax(0,1fr))",
              }}
              ref={squareRef}
            >
              <div
                className="Academics__section__content__row__column "
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: isMobile ? 13 : 8,
                }}
              >
                <div
                  className="section-title"
                  style={{
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
                  }}
                >
                  Middle School Curriculum
                </div>

                <div
                  className="section-p"
                  style={{
                    paddingRight: isMobile ? 0 : "64px",
                    marginBottom: isMobile ? "24px" : "56px",
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
                  }}
                >
                  Middle School (5th-8th grades) uses a subject-specialist
                  framework.
                </div>

                <div
                  className="section-p"
                  style={{
                    paddingRight: isMobile ? 0 : "64px",
                    marginBottom: isMobile ? "24px" : "56px",
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
                  }}
                >
                  Students rotate classrooms for each subject with a specialist
                  teacher. The language distribution continues to be 50/50
                  (alternating Spanish/English weekly) for core academic
                  subjects throughout Middle School, while Specials (Art, Music,
                  PE, Yoga) and Advisory are in Spanish.
                </div>
              </div>

              {!isMobile && (
                <div
                  className="Academics__section__content__row__column "
                  style={{
                    gridColumnStart: 8,
                    gridColumnEnd: 13,
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
                  }}
                >
                  <img
                    src={"academics-2.png"}
                    style={{
                      marginTop: "-24px",
                      height: dimensions.height,
                      width: "auto",
                    }}
                    alt=""
                  />
                </div>
              )}
            </div>

            <div
              className="section-p"
              style={{
                marginBottom: isMobile ? "24px" : "56px",
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
              }}
            >
              Every HoLa student benefits from advanced coursework in Middle
              School, including a progression of STEM courses ranging from
              coding and app development to robotics and 3-D modeling, a College
              Board-approved AP Spanish Language, and Culture course and Algebra
              I in 8th grade.
            </div>

            <div
              className="section-p"
              style={{
                marginBottom: isMobile ? "24px" : "56px",
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s",
              }}
            >
              All HoLa Middle School students have the opportunity to sit for
              the AP Spanish Language and Culture exam in 8th grade, and all
              graduating 8th graders take the Pathway to Biliteracy exam.
            </div>

            <div
              className="section-p"
              style={{
                marginBottom: isMobile ? "24px" : "56px",
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s",
              }}
            >
              HoLa’s hands-on curriculum continues through Middle School with
              opportunities to extend learning both in the classroom through
              projects and presentations, and out of the classroom, through a
              wealth of immersion and field trip opportunities in and around New
              York City. Our 8th grade immersion experience culminates in a
              class trip to Puerto Rico, where they are able to use their
              language skills in an authentic cultural setting.
            </div>
          </div>
        </div>
      )}
    </CellMeasurer>
  );
}

function ValuesSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <div className="Academics__section app-grid" ref={ref}>
      <div className="Academics__section__content ">
        <div
          className="section-title"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
          }}
        >
          HoLa Core Values
        </div>

        <div
          className="Academics__section__content__image"
          style={{
            marginBottom: "48px",
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
          }}
        >
          <img src={"academics-3.png"} alt="" />
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
          }}
        >
          HoLa’s core values define who we are as a school community and reflect
          our commitment to diversity, equity, and inclusivity, regardless of
          race, ethnicity, religion, gender, gender identity, physical or mental
          ability, sexual orientation, or socioeconomic status. Our common goals
          are academic excellence, bilingualism, and the cultivation of
          character strengths.
        </div>

        <div
          className="section-p"
          style={{
            opacity: isInView ? 1 : 0,
            transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.4s",
          }}
        >
          We expect members of our school community to embody these core values
          in the classroom, in the lunchroom, in the park, and online. We strive
          for a school climate that is joyful, academically rigorous, and safe
          for all.
        </div>
      </div>
    </div>
  );
}

function DiversitySection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });

  return (
    <CellMeasurer>
      {({ squareRef, dimensions }) => (
        <div className="Academics__section app-grid" ref={ref}>
          <div className="Academics__section__content ">
            <div
              className="Academics__section__content__row "
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(12, minmax(0,1fr))",
              }}
            >
              <div
                className="Academics__section__content__row__column "
                style={{
                  gridColumnStart: 1,
                  gridColumnEnd: isMobile ? 13 : 8,
                }}
                ref={squareRef}
              >
                <div
                  className="section-title"
                  style={{
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
                  }}
                >
                  Diversity at HoLa
                </div>

                <div
                  className="section-p"
                  style={{
                    paddingRight: isMobile ? 0 : "64px",
                    marginBottom: isMobile ? "24px" : "56px",
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.3s",
                  }}
                >
                  At HoLa, we are committed to being a heterogenous and
                  inclusive community with a culture of social justice, respect,
                  and equity. We actively seek to make the world more equitable
                  for people of all identities, backgrounds and beliefs,
                  starting within our own school. We commit to being a school in
                  which students, faculty, staff, families, and alumni feel
                  respected and valued for who they are. Students are better
                  educated and prepared to succeed in our global community when
                  the school community is diverse, inclusive and committed to
                  cultural proficiency.
                </div>
              </div>

              {!isMobile && (
                <div
                  className="Academics__section__content__row__column "
                  style={{
                    gridColumnStart: 8,
                    gridColumnEnd: 13,
                    opacity: isInView ? 1 : 0,
                    transition:
                      "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
                  }}
                >
                  <img
                    src={"academics-4.png"}
                    style={{ height: dimensions.height, width: "auto" }}
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </CellMeasurer>
  );
}

function PoliciesSection() {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.2 });
  const { error, data } = useQuery(POLICIES);

  if (error) {
    console.log(error);
  }

  // if (loading || !data) {
  //   return <div>Loading...</div>;
  // }

  const policies = data?.policies || [];

  return (
    <div
      className="Academics__section app-grid"
      style={{ paddingBottom: "48px" }}
      ref={ref}
    >
      <div
        className="Academics__section__content "
        style={{
          gridColumnStart: 2,
          gridColumnEnd: isMobile ? 12 : 13,
        }}
      >
        <div
          className="Academics__section__content__row "
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(12, minmax(0,1fr))",
          }}
        >
          <div
            className="section-title"
            style={{
              gridColumnStart: 1,
              gridColumnEnd: 13,
              opacity: isInView ? 1 : 0,
              transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.1s",
            }}
          >
            School Plans & Policies
          </div>
          {!isMobile && (
            <div
              className="Academics__section__content__row__column "
              style={{
                gridColumnStart: 1,
                gridColumnEnd: 6,
              }}
            >
              <img
                src={"academics-5.png"}
                style={{ maxWidth: "100%", height: "auto" }}
                alt=""
              />
            </div>
          )}

          <div
            className="Academics__section__content__row__column "
            style={{
              gridColumnStart: isMobile ? 1 : 7,
              gridColumnEnd: 13,
            }}
          >
            <div
              className="section-p"
              style={{
                paddingRight: isMobile ? "0" : "64px",
                marginBottom: isMobile ? "24px" : "56px",
                opacity: isInView ? 1 : 0,
                transition: "all 0.4s cubic-bezier(0.17, 0.55, 0.55, 1) 0.2s",
              }}
            >
              {policies.map((policy) => (
                <a
                  className="policy-link"
                  href={policy.url}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {policy.name}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Academics() {
  usePageTracking({
    title: "Academics",
  });

  return (
    <div className="Academics">
      <Nav />
      <Hero image={backgroundImage} text={"Academics"} />
      <PageCaption
        text={
          <span>
            <span className="bold">
              HoLa’s curriculum reflects more than three decades of research in
              immersion education and integrates best practices developed by
              HoLa as well as other model dual language programs from across the
              country.
            </span>
          </span>
        }
      />

      <DualLanguageSection />

      <ElementarySection />

      <MiddleSection />

      <ValuesSection />

      <DiversitySection />

      <PoliciesSection />

      <div className="PageCaption app-grid">
        <div
          className="PageCaption__content"
          style={{ justifyContent: "center" }}
        >
          <div className="PageCaption__text">
            For more information about Charters visit{" "}
            <a
              style={{ color: "var(--black)" }}
              href={"https://njcharters.org/about/"}
              target="_blank"
              rel="noreferrer"
              className="bold"
            >
              New Jersey Public Charter Schools Association.
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
